import * as React from 'react';
import {ComponentProps, PropsWithChildren, useMemo} from 'react';
import {
  activeLinkClass,
  Navigation as B2CNavigation,
} from '@edekadigital/b2c-components';
import {Link, LinkGetProps} from '@gatsbyjs/reach-router';
import {
  NAV_LOGOUT,
  NAV_NEWSLETTER,
  NAV_PAYBACK,
  NAV_PERSONAL_DATA,
  NAV_PRIVACY,
  ROUTE_DATENVERWALTUNG,
  ROUTE_INDEX,
  ROUTE_NEWSLETTER,
  ROUTE_PAYBACK,
} from '../../common/constants';
import {useAuth} from '../../common/contexts';
import {useCustomer} from '../../common/hooks';
import {newsletterActive, paybackVisible} from '../../common/utils/feature';
import {Theme, useMediaQuery} from '@mui/material';
import {getEnvironment} from '../../common/utils/getHost';

export const Navigation: React.FC = () => {
  const {logout} = useAuth();
  const {customerData} = useCustomer();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const environment = getEnvironment(window.location.host);

  const LogoImageProps = useMemo(() => {
    return {
      src: '/assets/images/logo_edeka.png',
      alt: 'EDEKA Logo',
      customHeight: isMobile ? '57.41px' : '72px',
      customWidth: isMobile ? '47.62px' : '60px',
      sources: [
        {
          srcSet:
            '/assets/images/logo_edeka_hdpi.png 2x, /assets/images/logo_edeka.png',
        },
      ],
    };
  }, [isMobile]);

  const navigationItems = useMemo(
    () => [
      {
        href: ROUTE_INDEX,
        label: NAV_PERSONAL_DATA,
      },
      ...(newsletterActive()
        ? [
            {
              href: ROUTE_NEWSLETTER,
              label: NAV_NEWSLETTER,
            },
          ]
        : []),
      {
        href: ROUTE_DATENVERWALTUNG,
        label: NAV_PRIVACY,
      },
      ...(paybackVisible() || environment === 'localhost'
        ? [
            {
              href: ROUTE_PAYBACK,
              label: NAV_PAYBACK,
            },
          ]
        : []),
    ],
    [customerData]
  );

  return (
    <B2CNavigation
      logoutLabel={NAV_LOGOUT}
      logoutHandler={logout}
      items={navigationItems}
      linkComponent={NavigationLink}
      LogoImageProps={LogoImageProps}
    />
  );
};

//TODO this is only a hack: the navigation needs to be reworked
type NavigationLinkProps = PropsWithChildren<ComponentProps<typeof Link>> & {
  to: string;
};

const NavigationLink: React.FC<NavigationLinkProps> = React.forwardRef<
  HTMLAnchorElement,
  NavigationLinkProps
>((props, ref) => {
  const isActive = (options: LinkGetProps) => ({
    className: options.isCurrent
      ? `${props.className} ${activeLinkClass}`
      : props.className,
  });
  return <Link {...props} ref={ref} getProps={isActive} />;
});
NavigationLink.displayName = 'NavigationLink';
